import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Dashboard', url: '/dashboard', icon: 'home' },
    { title: 'Assessment', url: 'assessment', icon: 'document' },
    { title: 'Subscription', url: 'subscription', icon: 'card' },
    { title: 'Settings', url: 'settings', icon: 'settings' },
    { title: 'Logout', url: 'login', icon: 'log-out' }
    
  ];
 
  constructor() {}
}
